.container{
  padding: 0 25px;
}
.content{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.logo{
  font-size: 21px;
  font-weight: 600;
  color: #fff;
}
.profile{
  cursor: pointer;
  background-color: #1677ff;
}
.right{
  display: flex;
  align-items: center;
}
.profileName{
  font-size: 16px;
  color: #fff;
  line-height: 18px;
  margin-right: 25px;
  & > strong{
    font-size: 17px;
  }
}