.container{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.loader{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
}
.projects{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 535px;
}
.project{
  width: calc(33.333% - 13.3333px);
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  &:nth-child(3n+3){
    margin-right: 0;
  }
}
.projectCover{
  height: 150px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.footer{
  padding-top: 45px;
  display: flex;
  justify-content: flex-end;
}