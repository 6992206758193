.container{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.actions{
  padding-top: 25px;
  width: 100%;
  display: flex;
  & > *:nth-child(1){
    margin-right: 10px;
  }
}