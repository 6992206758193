.quickActions{
  display: flex;
  margin-bottom: 45px;
  flex-direction: column;
}
.quickActionsItems{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.quickActionsItemCard{
 cursor: pointer;
 margin-right: 25px;
 min-width: 200px;
 margin-bottom: 25px;
}
.quickActionsItem{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > *:nth-child(1){
    font-size: 25px;
    margin-bottom: 15px;
  }
  & > *:nth-child(2){
    font-size: 16px;
    max-width: 150px;
    text-align: center;
  }
}