.loader{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content{
  min-height: 535px;
}
.updateForm{
  margin-bottom: 45px;
}