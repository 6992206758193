.container{
  padding: 15px 25px 15px 25px;
}
.label{
  display: block;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  padding: 8px;
  padding-bottom: 8px;
}